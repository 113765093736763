import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import { Power0 } from 'gsap'
import { colors, breakpoints } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '#components/Image'
import doubleQuotes from '#images/doubleQuotes.svg'
import ScrollParallax from '#lib/ScrollParallax'
import { timelineY } from '#globals/constants'

const styles = {
  container: css`
    background-color: ${colors.orangeRed};
    ${breakpoints({
      paddingTop: [40, 40, 180, 0, 0],
      paddingBottom: [80, 80, 80, 60, 60],
    })};
  `,
  title: css`
    line-height: 1.34;
  `,
  dblQuote: css`
    position: relative;
    top: 10px;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(${doubleQuotes});
    background-size: contain;
    background-repeat: no-repeat;
  `,
  thumbsup: css`
    ${breakpoints({
      top: [null, null, -420, -260, -260],
      right: [null, null, 'auto', 20, 20],
      left: [null, null, -120, 'auto', 'auto'],
    })};
  `,
  confetti: css`
    ${breakpoints({
      top: [null, null, -420, -360, -360],
      right: [null, null, -150, -150, -150],
    })};
  `,
}
function Testimonials() {
  const titleStyleProps = {
    size: 'xxxLarge',
    weight: 'bold',
    color: colors.black,
    css: styles.title,
  }
  const ctr = useRef(null)
  const thumbsup = useRef(null)
  useEffect(() => {
    const thumbsupOptions = {
      options: {
        SMtriggerElement: ctr.current,
        SMduration: '100%',
        SMtriggerHook: 1,
        SMoffset: -220,
        GSelement: thumbsup.current,
        GSduration: 2,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const thumbsupParallax = new ScrollParallax()

    thumbsupParallax.init(
      thumbsupOptions.options,
      thumbsupOptions.timelineVarsStart,
      thumbsupOptions.timelineVarsEnd,
    )

    return () => {
      thumbsupParallax.destroyController()
    }
  }, [])
  return (
    <section css={styles.container} ref={ctr}>
      <div className='container relative'>
        <div className='row'>
          <div className='col-lg-6'>
            <Image
              ref={thumbsup}
              css={styles.thumbsup}
              className='d-none d-md-block'
              alt='thumbsup'
              image='home/thumbsup'
              sizes={{
                lg: {
                  width: 673,
                  height: 609,
                },
                md: {
                  width: 470,
                },
              }}
            />
          </div>
          <div className='col-lg-6 text-lg-left text-center'>
            <blockquote
              css={css`
                margin-bottom: 0;
              `}
            >
              <span css={styles.dblQuote} />
              <Title {...titleStyleProps}>
                Short bursts of consistent daily practice are a
                proven, effective & smart way to learn.
              </Title>
              <Text
                color={colors.black}
                size='large'
                className='mt-3'
              >
                <strong>Faisal Nasim</strong> | <span>Founder</span>
              </Text>
            </blockquote>
          </div>
        </div>
        <Image
          lazy
          css={styles.confetti}
          className='d-none d-md-block'
          alt='confetti'
          image='home/confetti'
          sizes={{
            lg: {
              width: 290,
              height: 242,
            },
            md: {
              width: 240,
            },
          }}
        />
      </div>
    </section>
  )
}

export default Testimonials
