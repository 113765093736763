import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { ExternalLinkBtn } from '@dqp/common/components/Link/Link'
import { reactRevealDuration } from '#globals/constants'
import ribbons from '#images/home/ribbons.svg'
import routes from '#globals/routes'

const styles = {
  container: css`
    ${breakpoints({
      paddingTop: [80, 80, 120, 120, 120],
      paddingBottom: [80, 80, 120, 120, 120],
    })};
  `,
  title: css`
    ${spacer.mrB20}
  `,
  ribbons: css`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 110%;
    height: 110%;
    transform: translate(-50%, -58%);
  `,
}
function CTA() {
  const titleStyleProps = {
    size: 'xxxxxLarge',
    weight: 'bold',
    color: colors.white,
    css: styles.title,
  }

  return (
    <div
      className='outer'
      style={{
        background: colors.black,
        zIndex: 1,
        position: 'relative',
        // overflowY: 'hidden'
      }}
    >
      <section
        css={styles.container}
        style={{ zIndex: 3, position: 'relative' }}
      >
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <Fade clear cascade duration={reactRevealDuration}>
              <div className='col-lg-8'>
                <Title as='h2' {...titleStyleProps}>
                  Give it a try for free!
                </Title>
                <Text
                  color={colors.white}
                  size='large'
                  css={spacer.mrB40}
                >
                  Sign up now and try some questions and tutorials for
                  free <br />
                  (no credit card required).
                </Text>

                <ExternalLinkBtn
                  variant='blue'
                  size='large'
                  inline
                  to={routes.signup}
                >
                  TRY FOR FREE
                </ExternalLinkBtn>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <img
        alt='ribbons'
        css={styles.ribbons}
        src={ribbons}
        className='d-none d-md-block'
        style={{ zIndex: 2 }}
      />
    </div>
  )
}

export default CTA
