import React from 'react'
import Wave from '@dqp/common/components/Wave/Wave'
import { colors } from '@dqp/common/styles/utilities'
import Hero from './Hero'
import ZSection from './ZSection'
import AdviceAndTips from './AdviceAndTips'
import Testimonials from './Testimonials'
import CTA from './CTA'

function Home() {
  return (
    <>
      <Hero />
      <Wave bg={colors.yellow} fill={colors.purple} type='1' />
      <ZSection />
      <Wave bg={colors.purple} fill={colors.white} type='1' />
      <AdviceAndTips />
      <Wave bg={colors.white} fill={colors.orangeRed} type='2' />
      <Testimonials />
      <Wave bg={colors.orangeRed} fill={colors.black} type='3' />
      <CTA />
      <Wave bg={colors.black} fill={colors.white} type='footer' />
    </>
  )
}

export default Home
