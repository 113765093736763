import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import { Power0 } from 'gsap'
import { colors, breakpoints } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Title from '@dqp/common/components/Title/Title'
import { ExternalLinkBtn } from '@dqp/common/components/Link/Link'
import Image from '#components/Image'
import ScrollParallax from '#lib/ScrollParallax'
import { timelineY } from '#globals/constants'
import routes from '#globals/routes'

const styles = {
  contaner: css`
    background-color: ${colors.yellow};
    ${breakpoints({
      paddingTop: [40, 40, 120, 170, 170],
      paddingBottom: [80, 80, 120, 120, 120],
    })};
  `,
  brain: css`
    ${breakpoints({
      left: [null, null, -180, -270, -180],
      bottom: [null, null, -295, -395, -395],
    })};
  `,
  cloud: css`
    ${breakpoints({
      right: [null, null, -200, -200, -120],
      bottom: [null, null, -70, -70, -70],
    })};
  `,
  pen: css`
    ${breakpoints({
      top: [null, null, -45, -95, -95],
      right: [null, null, -135, -240, -150],
    })};
  `,
  rocket: css`
    ${breakpoints({
      top: [null, null, -80, -130, -130],
      left: [null, null, -230, -360, -280],
    })};
  `,
  sharpener: css`
    ${breakpoints({
      top: [null, null, -60, -110, -110],
      right: [null, null, -150, -170, -110],
    })};
  `,
}

function Hero() {
  const ctr = useRef(null)
  const pen = useRef(null)
  const brain = useRef(null)

  useEffect(() => {
    const brainOptions = {
      options: {
        SMtriggerElement: ctr.current,
        SMduration: '100%',
        SMtriggerHook: 0.09,
        GSelement: brain.current,
        GSduration: 1,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const penOptions = {
      options: {
        SMtriggerElement: ctr.current,
        SMduration: '100%',
        SMtriggerHook: 0.09,
        GSelement: pen.current,
        GSduration: 2,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const brainParallax = new ScrollParallax()
    const penParallax = new ScrollParallax()

    brainParallax.init(
      brainOptions.options,
      brainOptions.timelineVarsStart,
      brainOptions.timelineVarsEnd,
    )
    penParallax.init(
      penOptions.options,
      penOptions.timelineVarsStart,
      penOptions.timelineVarsEnd,
    )

    return () => {
      brainParallax.destroyController()
      penParallax.destroyController()
    }
  }, [])
  return (
    <section ref={ctr} css={styles.contaner}>
      <div className='relative container text-center'>
        <div className='row justify-content-center'>
          <div className='col-md-10 text-center'>
            <Title
              as='h1'
              size='xxxxxLarge'
              weight='bold'
              color={colors.black}
              css={styles.title}
            >
              Efficient and effective{' '}
              <br className='d-lg-block d-none' />
              11+ exam preparation
            </Title>
            <Text
              color={colors.charcoal}
              size='xLarge'
              className='py-4'
            >
              Targeted daily questions with detailed{' '}
              <br className='d-lg-block d-none' /> explanations and
              solutions.
            </Text>
            <ExternalLinkBtn
              variant='blue'
              size='large'
              inline
              to={routes.signup}
            >
              TRY FOR FREE
            </ExternalLinkBtn>
          </div>
        </div>
        <Image
          css={styles.rocket}
          className='d-none d-md-block'
          alt='rocket'
          image='home/rocket'
          sizes={{
            lg: {
              width: 444,
            },
            md: {
              width: 320,
            },
          }}
        />
        <Image
          ref={brain}
          css={styles.brain}
          className='d-none d-md-block'
          alt='brain'
          image='home/brain'
          sizes={{
            lg: {
              width: 539,
            },
            md: {
              width: 370,
            },
          }}
        />
        <Image
          css={styles.cloud}
          className='d-none d-md-block'
          alt='cloud'
          image='home/cloud'
          sizes={{
            lg: {
              width: 383,
              height: 226,
            },
            md: {
              width: 305,
              height: 180,
            },
          }}
        />
        <Image
          css={styles.sharpener}
          className='d-none d-md-block'
          alt='sharpener'
          image='home/sharpener'
          sizes={{
            lg: {
              width: 165,
              height: 209,
            },
            md: {
              width: 134,
              height: 170,
            },
          }}
        />
        <Image
          ref={pen}
          css={styles.pen}
          className='d-none d-md-block'
          alt='pen'
          image='home/pen'
          sizes={{
            lg: {
              width: 369,
              height: 408,
            },
            md: {
              width: 244,
              height: 270,
            },
          }}
        />
      </div>
    </section>
  )
}

export default Hero
