import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import PublicLayout from '#components/PublicLayout'
import SEO from '#components/SEO'
import Home from '#containers/Home/Home'
import heroimage from '#images/hero/home_hero.png'

const IndexPage = () => (
  <PublicLayout headerColor={colors.yellow}>
    <SEO
      title='Home'
      meta={[{ name: 'theme-color', content: colors.yellow }]}
      image={heroimage}
    />

    <Home />
  </PublicLayout>
)

export default IndexPage
