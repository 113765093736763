import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  breakpoints,
  colors,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import {
  reactRevealDuration,
  staggerDelay,
  reactRevealDistance,
} from '#globals/constants'
import OKhand from '#images/OKhand.svg'
import phoneWithWOW from '#images/phoneWithWOW.svg'
import coneWahoo from '#images/coneWahoo.svg'
import tadaBulb from '#images/tadaBulb.svg'
import blobBlack from '#images/home/blobBlack.svg'
import blobPurple from '#images/home/blobPurple.svg'
import blobRed from '#images/home/blobRed.svg'
import blobYellow from '#images/home/blobYellow.svg'

const styles = {
  container: css`
    ${breakpoints({
      paddingTop: [80, 80, 120, 140, 140],
      paddingBottom: [80, 80, 120, 120, 120],
    })};
  `,
  title: css`
    ${spacer.mrB30}
  `,
  imgCtr: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    ${breakpoints({
      width: [220, 220, 220, 'auto', 'auto'],
      height: [220, 220, 220, 150, 150],
    })};
  `,
  img: css`
    ${breakpoints({
      height: [80, 80, 80, 160, 160],
    })};
  `,
  blob: css`
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  `,
  imgText: css`
    max-width: 100%;
    ${breakpoints({
      marginTop: [-25, -25, -20, 30, 30],
    })};
  `,
}

const advices = [
  {
    blob: blobYellow,
    blobAlt: 'blob yellow',
    img: phoneWithWOW,
    imgAlt: 'phone with wow',
    text: 'Diagnostic assessment questions',
  },
  {
    blob: blobRed,
    blobAlt: 'blob red',
    img: tadaBulb,
    imgAlt: 'bulb',
    text: 'Actionable advice and insights',
  },
  {
    blob: blobBlack,
    blobAlt: 'blob black',
    img: coneWahoo,
    imgAlt: 'cone',
    text: 'Engaging and fun',
  },
  {
    blob: blobPurple,
    blobAlt: 'blob purple',
    img: OKhand,
    imgAlt: 'Ok hand',
    text: 'Expert video tutorials',
  },
]
function AdviceAndTips() {
  return (
    <section css={styles.container}>
      <div className='container'>
        <div className='row justify-content-center text-center mb-0 mb-lg-4'>
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='col-lg-10'>
              <Title
                as='h2'
                size='xxxxxLarge'
                weight='bold'
                color={colors.black}
                css={styles.title}
              >
                Advice and practical tips
              </Title>
              <Text
                size='large'
                color={colors.charcoal}
                css={spacer.mrB40}
              >
                We are exam preparation experts. As such, each
                tutorial contains plenty of actionable advice to help
                your child familiarise themselves with the format,
                build their confidence and hone their exam technique.
              </Text>
            </div>
          </Fade>
        </div>
        <div className='row justify-content-between'>
          {advices.map((advice, i) => (
            <Fade
              duration={reactRevealDuration}
              bottom
              distance={reactRevealDistance}
              key={advice.text}
              delay={i * staggerDelay}
            >
              <div
                className={`col-lg-2 col-sm-6 ${
                  i === advices.length - 1 ? '' : 'pb-4'
                }`}
              >
                <div className='d-flex align-items-center flex-column text-center'>
                  <div css={styles.imgCtr}>
                    <img
                      className='d-lg-none d-block'
                      css={styles.blob}
                      src={advice.blob}
                      alt={advice.blobAlt}
                    />
                    <img
                      css={css`
                        ${styles.img};
                        ${i === 0 &&
                          breakpoints({
                            marginLeft: [0, 0, 0, 30, 30],
                          })}
                      `}
                      src={advice.img}
                      alt={advice.imgAlt}
                    />
                  </div>
                  <Text
                    weight='bold'
                    align='center'
                    size='large'
                    color={colors.charcoal}
                    css={styles.imgText}
                  >
                    {advice.text}
                  </Text>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  )
}

export default AdviceAndTips
