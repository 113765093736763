import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import { colors, breakpoints } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import Image from '#components/Image'
import {
  reactRevealDuration,
  reactRevealDistance,
} from '#globals/constants'
import { externalSites } from '#globals/routes'

const styles = {
  container: css`
    background-color: ${colors.purple};

    ${breakpoints({
      paddingTop: [80, 80, 120, 180, 180],
      paddingBottom: [80, 80, 120, 180, 180],
    })};
  `,
  row: css`
    ${breakpoints({
      marginTop: [80, 80, 120, 240, 240],
    })};
  `,
  star: css`
    ${breakpoints({
      top: [null, null, null, -280, -280],
      left: [null, null, null, -40, -40],
    })};
  `,
  crown: css`
    ${breakpoints({
      top: [null, null, null, -235, -235],
      right: [null, null, null, -30, -30],
    })};
  `,
  camera: css`
    ${breakpoints({
      top: [null, null, null, -230, -230],
      left: [null, null, null, 10, 10],
    })};
  `,
  clip: css`
    ${breakpoints({
      top: [null, null, null, 0, 0],
      left: [null, null, null, -100, -90],
    })};
  `,
  clapperBoard: css`
    ${breakpoints({
      bottom: [null, -230, -290, -450, -450],
      left: [null, -130, -100, -140, -125],
    })};
  `,
}

function ZSection() {
  const titleStyleProps = {
    size: 'xxxxLarge',
    weight: 'bold',
    color: colors.white,
    className: 'pb-3',
  }

  return (
    <section css={styles.container}>
      <div className='container relative'>
        <div
          className='row align-items-center justify-content-center'
          css={css`
            ${styles.row};
            margin-top: 0 !important;
          `}
        >
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='offset-lg-1 col-lg-5 col-md-10 text-center text-lg-left'>
              <Title as='h2' {...titleStyleProps}>
                What is Daily Quest Plus?
              </Title>
              <Text size='large'>
                Brought to you by the experts at{' '}
                <ExternalLink
                  to={externalSites.examPaperPlus}
                  target='_blank'
                  aria-label='exampapersplus-link'
                  borderColor='white'
                  hoverColor='black'
                >
                  Exam Papers Plus
                </ExternalLink>
                , Daily Quest Plus is a unique new way to prepare for
                11+ exams. A comprehensive solution that will help
                your child learn and improve rapidly.
              </Text>
            </div>
          </Fade>
          <Fade right distance='50px' duration={reactRevealDuration}>
            <div className='col-lg-6 relative d-lg-block d-none'>
              <Image
                css={styles.star}
                alt='star'
                image='home/star'
                sizes={{
                  lg: {
                    width: 658,
                    height: 586,
                  },
                  md: {},
                }}
              />
            </div>
          </Fade>
        </div>

        <div
          className='row align-items-center justify-content-center'
          css={css`
            ${styles.row};
          `}
        >
          <Fade left distance='50px' duration={reactRevealDuration}>
            <div className='col-lg-6 relative d-lg-block d-none'>
              <Image
                lazy
                css={styles.crown}
                className='d-none d-lg-block'
                alt='crown'
                image='home/crown'
                sizes={{
                  lg: {
                    width: 792,
                    height: 648,
                  },
                  md: {},
                }}
              />
            </div>
          </Fade>
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='offset-lg-1 col-lg-5 col-md-10 text-center text-lg-left'>
              <Title as='h2' {...titleStyleProps}>
                Practice makes perfect
              </Title>
              <Text size='large'>
                Short bursts of consistent daily practice has been
                proven to be an effective and smart way to learn and
                improve any skill. 11+ exam preparation is no
                exception. Daily Quest Plus will test and stretch your
                child, keeping them in a constant state of learning
                and improvement.
              </Text>
            </div>
          </Fade>
        </div>

        <div
          className='row align-items-center justify-content-center'
          css={css`
            ${styles.row};
            margin-bottom: 0;
          `}
        >
          <Fade clear cascade duration={reactRevealDuration}>
            <div className='offset-lg-1 col-lg-5 col-md-10 text-center text-lg-left'>
              <Title as='h2' {...titleStyleProps}>
                Diagnostic Questions + Video Tutorials
              </Title>
              <Text size='large'>
                High quality diagnostic questions accompanied by
                detailed video tutorials that cover everything your
                child needs to know.
              </Text>
            </div>
          </Fade>
          <Fade
            right
            distance={reactRevealDistance}
            duration={reactRevealDuration}
          >
            <div className='col-lg-6 relative d-lg-block d-none'>
              <Image
                lazy
                css={styles.camera}
                className='d-none d-lg-block'
                alt='camera'
                image='home/camera'
                sizes={{
                  lg: {
                    width: 914,
                    height: 774,
                  },
                  md: {},
                }}
              />
            </div>
          </Fade>
        </div>
        <Image
          lazy
          css={styles.clip}
          className='d-none d-lg-block'
          alt='clip'
          image='home/clip'
          sizes={{
            lg: {
              width: 146,
              height: 117,
            },
            md: {
              width: 0,
              height: 0,
            },
          }}
        />
        <Image
          lazy
          css={styles.clapperBoard}
          className='d-none d-sm-block'
          alt='clapperBoard'
          image='home/clapper-board'
          sizes={{
            lg: {
              width: 424,
              height: 381,
            },
            md: {
              width: 300,
            },
            sm: {
              width: 225,
            },
          }}
        />
      </div>
    </section>
  )
}

export default ZSection
